import { PropsWithChildren } from "react";

function Breadcrumb({ last, children }: PropsWithChildren<{ last: boolean }>) {
    return (
        <li className="float-left h-[27px] box-border">
            <span className={`relative block pr-5 mr-2 ${!last ? "before:bg-breadcrumb before:w-3 before:h-6 before:absolute before:top-0 before:right-0" : ""}`}>
                {children}
            </span>
        </li>
    );
}

export function Breadcrumbs() {
    const crumbs = ["News", "Topic", "Producer from the Letter LIVE Part XXV Airs Tuesday, 15 March"];
    return (
        <div className="w-full h-[27px] mb-[10px] text-[12px] leading-[27px]">
            <ul className="w-content mx-auto">
                <Breadcrumb last={false}>
                    <span className="w-5 h-5 bg-lodestone-mini p-3 block bg-no-repeat mt-0.5 ml-1" />
                </Breadcrumb>
                {crumbs.map((crumb, idx) => (
                    <Breadcrumb key={idx} last={idx === crumbs.length - 1}>
                        {crumb}
                    </Breadcrumb>
                ))}
            </ul>
        </div>
    );
}
