import React from "react";

export function Header() {
    return (
        <header className="h-[80px] bg-[#d6d4cf] text-[12px]">
            <div className="w-content h-[80px] bg-ew-header mx-auto flex flex-row-reverse">
                <div className="mt-[10px] select-none">
                    <span className="py-[10px] pl-[12px] pr-[10px] bg-slate-800 rounded-md shadow w-[298px] h-[60px] flex flex-row gap-4">
                        <img src="img/login-man.png" alt="Login Man" className="w-[40px] rounded-full" />
                        <div>
                            <p className="text-gray-100">Cannot login, don't try.</p>
                            <div className="text-blue-600 text-[16px] font-bold">Don't log in</div>
                        </div>
                    </span>
                </div>
            </div>
        </header>
    );
}
