
export function Footer() {
    return (
        <footer className="bg-slate-600 w-full mx-auto -mb-[20px]">
            <div className="w-full mx-auto pt-4 text-slate-300">
                <div className="w-[328px] h-8 mx-auto mb-8">
                    <img src="img/ffxiv_lodestone.png" alt="FFXIV Lodestone" />
                </div>
                <div className="mb-8 text-center">
                    <span className="inline-block bg-slate-700 w-36 h-7 rounded text-xs text-center leading-[28px] mx-2">
                        Game Download
                    </span>
                </div>
            </div>

            <div className="py-2 bg-slate-800">
                <div className="w-content mx-auto clearfix">
                    <div className="float-left w-[479px] border-r border-white border-opacity-10 pt-2">
                        <p className="pr-4 pb-4 text-slate-400 text-[10px] leading-[1.6]">
                            Something something legal stuff about copyright that I may or may not own.
                            <br/><br/>
                            This is just a parody website, please don't sue me Square Enix if you find this.<br/>
                            All rights belong to Square Enix.
                        </p>
                    </div>
                    <div className="float-left w-[480px] pt-2">
                        <p className="pl-4 text-slate-400 text-[10px] leading-[1.6]">
                            © 2010 - 2022 SQUARE ENIX CO., LTD. All Rights Reserved.<br/>ENDWALKER, SHADOWBRINGERS, STORMBLOOD, HEAVENSWARD and A REALM REBORN are registered trademarks or trademarks of Square Enix Co., Ltd.<br/>FINAL FANTASY, FINAL FANTASY XIV, FFXIV, SQUARE ENIX and the SQUARE ENIX logo are registered trademarks or trademarks of Square Enix Holdings Co., Ltd.<br/>LOGO ILLUSTRATION: © 2010, 2014, 2016, 2018, 2021 YOSHITAKA AMANO
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
