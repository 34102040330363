
export function SideBar() {
    return (
        <div className="float-right w-[300px] mr-[10px]">
            <div className="mb-[10px] h-[62px]">
                <img src="img/forums.png" alt="Forums" className="block w-[100px] h-[62px] float-left rounded-l-md bg-[#ccc4ad] shadow-md" />
                <img src="img/mogstation_big.png" alt="Mogstation" className="block w-[100px] h-[62px] float-left bg-[#e6e6e6] shadow-md" />
                <img src="img/devblog.png" alt="Devblog" className="block w-[100px] h-[62px] float-left rounded-r-md bg-[#1b2a59] shadow-md" />
            </div>

            <div className="mb-[10px]">
                <img src="img/ew_available.jpg" alt="Endwalker available" />
            </div>

            <div className="mb-[10px]">
                <img src="img/onlinestore.jpg" alt="Onlinestore" />
            </div>

            <div className="mb-[10px]">
                <img src="img/little_ladies.jpg" alt="Little Ladies Day" />
            </div>

            <div className="mb-[10px]">
                <img src="img/mogtreasure.jpg" alt="Moogle Treasure Trove" />
            </div>

            <div className="mb-[10px]">
                <img src="img/jobguide_v60_eu.jpg" alt="Jobguide" />
            </div>

            <div className="mb-[10px]">
                <img src="img/crafting_gathering.jpg" alt="Who cares honestly" />
            </div>

            <div className="mb-[10px]">
                <img src="img/uiguide_eu.jpg" alt="Who cares honestly" />
            </div>

            <div className="mb-[10px]">
                <img src="img/skip.jpg" alt="Who cares honestly" />
            </div>

            <div className="mb-[10px]">
                <img src="img/recruit_a_friend.png" alt="Who cares honestly" />
            </div>
        </div>
    );
}
