import { PropsWithChildren } from "react";

function NavItem({ active, children }: PropsWithChildren<{ active: boolean }>) {
    return (
        <li className="w-40 h-12 list-none float-left">
            <span className={`flex w-40 h-12 items-center justify-center ${active ? "bg-slate-700" : "bg-slate-900"} text-gray-100 font-bold font-[14px]`}>
                {children}
            </span>
        </li>
    );
}

export function NavBar() {
    const navItems = ["News", "Getting Started", "Play Guide", "Community", "Standings", "Help & Support"];

    return (
        <nav className="bg-slate-900 clearfix">
            <ul className="w-content mx-auto list-none">
                {navItems.map((item, idx) =>
                    <NavItem key={idx} active={idx === 0}>
                        {item}
                    </NavItem>
                )}
            </ul>
        </nav>
    );
}
