import React from "react";
export function HeaderBar() {
    return (
        <div className="h-[38px] px-2 gap-4 flex items-center bg-slate-900 text-[12px]">
            <img src="img/sqex.png" alt="SQEX" width={130} height={14} />
            <div className="flex-grow" />
            <input className="rounded-full px-3 w-32 bg-slate-700 placeholder:text-slate-500" placeholder="Search" disabled />
            <select className="rounded-full px-2 w-60" disabled>
                <option value={"English"} selected>English</option>
            </select>
        </div>
    );
}
