import { PropsWithChildren } from "react";

function ContentLI({ children } : PropsWithChildren<{}>) {
    return (
        <li className="pl-7 mb-2 bg-articleli bg-no-repeat leading-[1.4] [background-position:6px_1px] [background-size:16px_16px]">
            {children}
        </li>
    );
}

export function MainContent() {
    return (
        <div className="float-left w-[620px] ml-[10px]">
            <article className="my-[10px] bg-gray-200 text-gray-600 shadow-lg relative before:absolute before:left-0 before:-bottom-2 before:rounded-b-md before:w-full before:h-2 before:bg-slate-900 after:absolute after:left-0 after:-top-2 after:rounded-t-md after:w-full after:h-2 after:bg-slate-900">
                <header className="py-[10px] mb-6 bg-slate-600 text-sm">
                    <span className="block py-0.5 pr-[30px] pl-[40px] text-gray-300 bg-topic bg-no-repeat [background-size:24px_24px] [background-position:13px_-1px]">
                        {new Date().toLocaleString()}
                    </span>
                    <h1 className="pr-[30px] pl-[40px] text-base font-bold text-gray-100">
                        Producer from the Letter LIVE Part XXV Airs Tuesday, 15 March
                    </h1>
                </header>

                <div className="px-[30px] pb-[24px] text-sm">
                    <img src="img/lpl.png" alt="LPL" className="block w-[560px] mx-auto mb-[24px]" />
                    <p className="mb-[32px]">
                        The next Producer from the Letter LIVE broadcast is set to air on <span className="text-orange-400 font-bold">Friday, 15 March at 21:00 (GMT+1)</span> and will feature our first look at Senns Slayer!
                        <br/><br/>
                        Additionally, co-watching via TogetherEnns is allowed for this broadcast, so feel free to enjoy the show with your community and nihilists around the world!
                    </p>
                    <h3 className="mb-4 pb-0.5 text-green-600 text-lg border-b-[3px] border-green-700">Producer from the Letter LIVE Part XXV</h3>
                    <h4 className="text-green-600 mb-2 font-bold">Date &amp; Time</h4>
                    <p className="mb-[24px]">
                        <span className="text-orange-400 font-bold">Friday, 15 March at 21:00 (GMT+1)</span>
                        <br/>
                        <span className="text-red-700">* Starting time is subject to change. Especially counting in the Sebb'sche timezone.</span>
                    </p>
                    <h4 className="text-green-600 mb-2 font-bold">Where to Watch</h4>
                    <ul className="mb-0">
                        <ContentLI>
                            Youtube
                        </ContentLI>
                    </ul>
                    <p className="mb-4">
                        <span className="text-red-700">* Please note that the presentation slides will include both Japanese and English text, the presentation audio will be in Japanese, with a live translation into English.</span>
                    </p>
                    <h4 className="text-green-600 mb-2 font-bold">Show Details</h4>
                    <ul className="mb-6">
                        <ContentLI>
                            Senns Slayer Part 1
                        </ContentLI>
                        <ContentLI>
                            Miscellaneous Updates
                        </ContentLI>
                    </ul>
                </div>

                <div className="bg-slate-600 text-gray-300">
                    <div className="flex justify-between py-[7px] px-[10px] text-sm">
                        <span>&lt; Older</span>
                        <span>Topics</span>
                        <span>Newer &gt;</span>
                    </div>
                </div>
            </article>
        </div>
    );
}
