import React from "react";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { HeaderBar } from "./components/HeaderBar";
import { MainContent } from "./components/MainContent";
import { NavBar } from "./components/NavBar";
import { SideBar } from "./components/SideBar";

function App() {
    return (
        <div>
            <HeaderBar />
            <Header />
            <NavBar />

            <div className="bg-slate-700 text-slate-200 min-h-screen clearfix">
                <Breadcrumbs />
                <h1 className="w-content mx-auto mb-[10px] py-1 px-2 text-center font-bold bg-[#334b80] text-white shadow-lg rounded-[32px]">Topics</h1>
                <div className="w-content mx-auto pb-10">
                    <MainContent />
                    <SideBar />
                </div>
            </div>

            <div className="w-full py-2 bg-slate-600 text-center">
                <span className="text-[10px] text-slate-200">
                    There is no mobile version :)
                </span>
            </div>
            <Footer />
        </div>
    );
}

export default App;
